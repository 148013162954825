///// Font Awesome Setup /////
$fa-font-path:        "../webfonts";  // for building. not the path in production

///// Override coreui/bootstrap4 variables here /////


///// --- /////
$layout-width-left: 210px;
$layout-width-middle: 570px;
$layout-width-right: 200px;
$layout-keyvis-height:110px;
$layout-nav-height:20px;
$layout-foot-height:20px;
