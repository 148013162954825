/***********
 IMPORTS
 **********/
// our variables
@import "variables";
// bootstrap
@import "../../node_modules/bootstrap/scss/bootstrap";
// font awesome
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/light.scss";
// other
@import "../../node_modules/animate.css/animate.css";

///////// BASE LAYOUT

.basic-container
{
  position:relative;
  margin:0 auto;
  width:980px;
}

.basic-top
{
  position:relative;

  .languageNav
  {
    position:absolute;
    left:785px;
    top: 85px;
    width:200px;
    z-index:1000;
    a, a:link, a:visited
    {
      font-size:11px;
      color:black;
      font-weight:bold;
    }
  }
}

.basic-visual
{
  position:relative;
  height:$layout-keyvis-height;
  .left
  {
    background-color:#969FBC;
    width:$layout-width-left;
    position:absolute;
    left:0;
    height:$layout-keyvis-height;
  }

  .keyvisual
  {
    position:absolute;
    left:210px;
    height:$layout-keyvis-height;
  }

  .right
  {
    background-color:#BEC8E3;
    position:absolute;
    left:780px;
    width:$layout-width-right;
    height:$layout-keyvis-height;
  }
}

.basic-navigation-area
{
  position:relative;
  height:$layout-nav-height;
  .left
  {
    background-color:#7475A3;
    width:$layout-width-left;
    height:$layout-nav-height;
    position:absolute;
    left:0;
  }

  .navigation
  {
    position:absolute;
    left:210px;
    height:$layout-nav-height;
    background-color:#2C2D70;
    width:$layout-width-middle;
    font-family: sans-serif;
    overflow:hidden;

    a
    {
      color:white;
      font-size:80%;
      font-weight:bold;
      padding:0 10px 0 10px;
      display:block;
      float:left;

      border-width: 1px;
      border-style: none solid none none;
      border-color: #7475A3;

      &.active, &:hover
      {
        background-color:#0403A9;
        text-decoration:none;
      }

      &.last
      {
        border-style:none;
        padding-right:0; /* some extra safe space to prevent line-wrap (could make the last item invisisble) */
      }

    }



  }

  .right
  {
    background-color:#646498;
    width:$layout-width-right;
    height:$layout-nav-height;
    position:absolute;
    left:780px;
    line-height:1;
  }
}

.basic-content-area
{
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;

  .left
  {
    flex-basis: $layout-width-left;
    background-color:#E4E7F0;
  }
  .content
  {
    flex-basis: $layout-width-middle;
    min-height:600px;
  }

  .leftAndContent
  {
    flex-basis: ($layout-width-left+$layout-width-middle);
    min-height:600px;
  }

  .right
  {
    flex-basis: $layout-width-right;
    background-color:#E4E7F0;
  }
}

.basic-footer
{
  position:relative;
  height:$layout-foot-height;

  .left
  {
    background-color:#E4E7F0;
    width:$layout-width-left;
    height:$layout-foot-height;
    position:absolute;
    left:0;
  }

  .middle
  {
    line-height:0;
    overflow:hidden;
    position:absolute;
    left:210px;
    height:$layout-foot-height;
    background-color:#F9F9FB;
    width:$layout-width-middle;
  }

  .right
  {
    line-height:0;
    overflow:hidden;
    background-color:#E4E7F0;
    width:$layout-width-right;
    height:$layout-foot-height;
    position:absolute;
    left:780px;
  }

}

.final-footer
{
  background-color:#636598;
  text-align:right;
  padding-right:25px;
  min-height:20px;
  color:white;
  font-size:14px;
  a:link, a:visited {
    color:white;
  }
}

.inner
{
  margin: 40px 120px 0 30px;
  font-size:14px;
  color: #2C2D70;
  font-family: Arial, sans-serif;
  line-height:1.3;
}

//////// Content Layout Styling

@mixin defaultBackground($image) {
  background-position:right top;
  background-repeat: no-repeat;
  background-image:url($image);
}
.w2
{
  @include defaultBackground('../images/welt_2.jpg');
}

.ord
{
  @include defaultBackground('../images/welt_mit_ord.jpg');
}

.w1
{
  @include defaultBackground('../images/welt_1.jpg');
}

.w3
{
  @include defaultBackground('../images/welt_3.jpg');
}

.schule
{
  @include defaultBackground('../images/schule_b.jpg');
}

.schule-left
{
  @include defaultBackground('../images/schule_a.jpg');
}

.w4
{
  background-color:#F9F9FB;
}

.w5
{
  background-color:#eee;
}

.w6
{
  background-color:#8DABC3;
}

///////// Content Styles

.title
{
  font-size: 120%;
  font-weight: bold;
  margin-bottom:20px;
}

.citation
{
  font-family: 'Architects Daughter', cursive;

  a, a:link, a:visited {
    color:black;
    text-decoration: none;
    cursor:default;
  }
}


///// Left Navi
.sidenav
{
  margin-top:40px;
  font-size:14px;

  ul
  {
    margin:0;
    padding:0;
    text-align:left;
    font-weight:bold;

    li
    {
      list-style: none;
      padding:2px 0;

      &.active, &:hover
      {
        background-color:#C6CCDA;
      }

      &.subnav
      {
        padding-left:10px;
      }

      &.heading
      {
        padding:0 30px;
        margin-top:30px;
        color:black;
        margin-bottom:10px;

        &:hover
        {
          background-color:transparent;
        }
      }

      a, a:link, a:visited
      {
        padding:0 10px 0 30px;
        display:block;
        color:#2C2976;
      }
      a:hover
      {
        text-decoration:none;
      }

    }
  }
}

.sidepic
{
  position:absolute;
  top:350px;
  right:15px;
  width:300px;
  height:250px;

  img
  {
    width:300px;
    height:auto;
  }
}

.contentpic
{
  img
  {
    width:90%;
  }
}



/***********
 ANIMATIONS
 **********/
.animated {
  animation-duration: 1s;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}


/////////////// SLIDER //////////////////////////////////////
.slider {
  width: 570px;
  height: 110px;
  margin: 0;
  position: relative;
}

.slide1,.slide2,.slide3,.slide4,.slide5 {
  position: absolute;
  width: 100%;
  height: 100%;
}

.slide1 {
  background: url('../images/start/foyer.png') no-repeat center;
  background-size: cover;
  /*animation:fade 32s infinite;
  -webkit-animation:fade 32s infinite;*/
}
/*
.slide2 {
  background: url('../images/start/puzzle.png') no-repeat center;
  background-size: cover;
  animation:fade2 32s infinite;
  -webkit-animation:fade2 32s infinite;
}
.slide3 {
  background: url('../images/start/stift.png') no-repeat center;
  background-size: cover;
  animation:fade3 32s infinite;
  -webkit-animation:fade3 32s infinite;
}
@keyframes fade
{
  0%   {opacity:1}
  33.333% { opacity: 0}
  66.666% { opacity: 0}
  100% { opacity: 1}
}
@keyframes fade2
{
  0%   {opacity:0}
  33.333% { opacity: 1}
  66.666% { opacity: 0 }
  100% { opacity: 0}
}
@keyframes fade3
{
  0%   {opacity:0}
  33.333% { opacity: 0}
  66.666% { opacity: 1}
  100% { opacity: 0}
}
*/
////////////////////////////////////////////////

.infofooter
{
  color: darkgrey;
  font-size:80%;
  text-align:justify;
  margin:15px 0;
  a, a:link, a:visited {
    color: darkgrey;
    text-decoration: underline;
  }
}

.intro
{
  font-size:120%;
  margin-bottom:10px;
  a, a:link, a:visited
  {
    color:#212529;
  }
}

.homeslider {
  width: 100%;
  height:100%;
  margin: 0;
  position: relative;
  padding-left:5px;
}

.homeslide1,.homeslide2 {
  position: absolute;
  width: 100%;
  height: 100%;
}

.homeslide1
{
  background: url('../images/start_51a.jpg') no-repeat center;
  animation:hofade1 12s infinite;
  -webkit-animation:hofade1 12s infinite;
  z-index:5;
  background-size: cover;
}

.homeslide2
{
  background: url('../images/start_51b.jpg') no-repeat center;
  background-size: cover;
}
@keyframes hofade1
{                   // 20% Zeit Bild1, 30% Zeit Transition, 20% Zeit Bild2, 30% Zeit Transition zurück
  0%   {opacity:1}
  20%   {opacity:1}
  50%   {opacity:0}
  70%    {opacity:0}
  100% { opacity: 1}
}

.imgshadow
{
  box-shadow: 6px 6px 5px 0 rgba(0,0,0,0.75);
}
